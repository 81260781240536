.bg-container {
  z-index: -1;
  position: absolute;
  height: 100vh;
  min-height: 880px;
  min-width: 100%;
  max-width: 100%;
  top: 0px;
  overflow: hidden;
}

.background {
  background-repeat: no-repeat;
  background-size: cover;
  background-color: lightgray;
  background-position: 50%;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -2;
  top: 0px;
  transform: scale(1.1);
}

.background-tint {
  position: absolute;
  height: 100vh;
  min-height: 880px;
  min-width: 100%;
  z-index: -1;
  opacity: 0.95;
  top: 0px;
}
