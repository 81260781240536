body {
  font-family: 'DM Sans';
  font-style: regular;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0px;
}

a {
  text-decoration: none;
}

button {
  background-color: transparent;
}
