.header-text-faq {
  padding-top: 10%;
}

.arrow-down {
  width: 284px;
  height: 121px;
  margin-bottom: 10vh;
}

.content-faq {
  background: var(--backgrounds-tertiary, #1c1617);
  display: flex;
  padding: 10vh 15vw;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.header-faq {
  color: var(--backgrounds-primary, #120e0f);
  height: calc(100vh - 80px);
  min-height: 800px;
  margin-left: 10%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mirror {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.faq-row {
  border-bottom: 1px solid var(--neon-bright-blue, #8ff2ff);
  display: flex;
  padding: 24px;
  flex-direction: column;
}

.remove-border {
  border-bottom: 0px;
  padding: 0px;
}

.faq-header-container {
  display: flex;
  cursor: pointer;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}

.faq-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 48px;

  border-radius: 8px;
  background: var(--backgrounds-primary, #120e0f);

  /* Large Glow/Blue */
  box-shadow: 0px 0px 50px 0px rgba(143, 242, 255, 0.25);
  z-index: 2;
}

.faq-header {
  color: var(--text-white, #fff);
  flex: 1;
}

.faq-container-footer {
  width: 100%;
  padding: 5vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  border-radius: 8px;
  background: var(--backgrounds-primary, #120e0f);
  /* Large Glow/Blue */
  box-shadow: 0px 0px 50px 0px rgba(143, 242, 255, 0.25);
  color: var(--neon-bright-blue, #8ff2ff);
  box-sizing: border-box;
}

.confused {
  width: 81px;
  border-radius: 8px;
  height: 68px;
  margin-right: 12px;
}

.contact-us {
  justify-self: center;
}

.faq-description-container {
  border-radius: 8px 0px 0px 8px;
  background: var(--backgrounds-primary, #120e0f);
  padding: 32px;
  width: 50%;
  align-self: flex-end;
}

.faq-description-content {
  color: var(--text-white, #fff);
  font-style: italic;
}

.arrow-right-faq {
  width: 32px;
  height: 32px;
  flex-shrink: 1;
  justify-content: center;
  align-items: center;
  transition: all ease 0.6s;
  filter: invert(51%) sepia(6%) saturate(604%) hue-rotate(304deg) brightness(101%) contrast(93%);
  margin-left: 12px;
}

.faq-text {
  color: var(--neon-bright-white, #e8e8e8);
  flex: 2;
}

.faq-item {
  display: flex;
  max-height: 0px;
  align-self: stretch;
  justify-content: space-between;
  overflow: hidden;
  transition: all ease 0.6s;
}

.faq-animation {
  max-width: 100%;
  max-height: 200px;
}

.animation-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.faq-animate-open {
  max-height: 600px;
  margin-top: 24px;
}

.highlighted-header {
  color: var(--neon-bright-blue, #8ff2ff);
  /* Element Glow/Blue */
  text-shadow: 0px 0px 20px #00a2fd;
}

.arrow-animate-faq {
  transform: scaleX(-1) rotate(-90deg);
  filter: none;
}

@media only screen and (max-width: 799px) {
  .header-faq {
    margin-left: 6vw;
  }

  .content-faq {
    padding: 10vh 6vw;
  }

  .faq-item {
    flex-direction: column;
  }

  .faq-animation {
    max-width: 50%;
  }

  .animation-container {
    justify-content: center;
    margin-top: 24px;
  }

  .faq-container {
    padding: 12px;
  }

  .faq-container-footer {
    flex-direction: column;
    padding: 12px;
  }

  .faq-header-container-confused {
    flex-direction: column;
  }

  .confused {
    margin-bottom: 16px;
  }

  .contact-us {
    margin-top: 16px;
  }
}
