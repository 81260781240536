.pp-page {
  background: var(--backgrounds-primary, #120e0f);
  display: flex;
  flex-direction: column;
  padding: 5% 10%;
}

.pp-header {
  color: var(--neon-bright-red, #ff8f8f);
  /* Element Glow/Red */
  text-shadow: 0px 0px 20px #fd0015;
}

.pp-date {
  color: var(--text-white, #fff);
  margin-top: 40px;
}

.pp-reminder {
  color: var(--text-light-gray, #928183);
  margin-top: 12px;
}

.pp-row-container {
  margin-top: 68px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.pp-row {
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: var(--text-white, #fff);
  opacity: 0.8;
}

.pp-row-val {
  max-width: 80%;
}

.pp-line {
  background: #928183;
  height: 1px;
  flex-grow: 1;
}

.pp-go-to {
  border-radius: 24px;
  border: 1px solid var(--backgrounds-primary-neutral, #3f3637);
  background: var(--backgrounds-primary, #120e0f);
  display: flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  color: var(--text-white, #fff);
  text-align: right;
}

.pp-container {
  display: flex;
  padding: 48px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  border-radius: 24px;
  background: var(--backgrounds-tertiary, #1c1617);
}

.pp-content-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 64px;
}

.pp-text {
  color: var(--text-light-gray, #928183);
}

.pp-context-header {
  color: var(--neon-bright-red, #ff8f8f);
}

.pp-text-highlight {
  color: var(--text-white, #fff);
}

.pp-text-subheader {
  color: var(--neon-bright-red, #ff8f8f);
}

.pp-margin {
  margin-left: 32px;
}

.pp-margin-2 {
  margin-left: 64px;
}

.pp-margin-3 {
  margin-left: 96px;
}

.pp-margin-0 {
  margin-left: -32px;
}
