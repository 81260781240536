.carousel-left {
  z-index: 100;
  position: absolute;
  width: 50%;
  height: 100%;
  cursor: pointer;
}

.carousel-right {
  z-index: 100;
  top: 0;
  right: 0;
  position: absolute;
  width: 50%;
  height: 100%;
  cursor: pointer;
}

.header-hosts {
  color: var(--backgrounds-primary, #120e0f);
  height: calc(100vh - 80px);
  min-height: 800px;
  margin-left: 10%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header-hosts-text {
  padding-top: 10%;
}

.arrows {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  width: 284px;
  max-width: 80%;
  height: auto;
  margin-bottom: 10vh;
}

.microphone {
  max-height: 434px;
  height: 40vw;
  width: auto;
  position: absolute;
  right: 5%;
  top: 35%;
}

.hosts-container {
  background: var(--backgrounds-primary, #120e0f);
  padding-top: 5%;
  overflow: hidden;
}

.carousel-container {
  width: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  position: relative;
}

.details-container {
  display: flex;
  padding: 0px 13% 7% 13%;
}

.help-wanted-container {
  background: var(--backgrounds-tertiary, #1c1617);
  min-height: 80vh;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 48px 15%;
  box-sizing: border-box;
}

.help-wanted-left {
  margin-right: 10vw;
}

.help-wanted-header {
  color: var(--neon-bright-yellow, #fdff8f);
  /* Element Glow/Yellow */
  text-shadow: 0px 0px 20px #fdf300;
  margin-bottom: 16px;
}

.help-wanted-subheader {
  color: var(--text-light-gray, #928183);
  margin-bottom: 24px;
}

.eyes {
  width: 209px;
  height: 148px;
}

.slider-animation {
  transition: transform 0.5s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  will-change: transform;
}

.slider-container {
  overflow: visible;
  width: 540px;
  max-width: 100vw;
  height: 480px;
  margin: auto;
}

.arrow-left {
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 210px;
  z-index: 99;
}

.left-arrow {
  left: 15%;
}

.flipped {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  right: 15%;
}

.slider {
  width: 210%;
  transition: all ease 0.6s;
}

.carousel-item {
  width: 11.111%;
  /* padding-bottom: 11.1111%; */

  float: left;

  opacity: 1;
  transition-property: opacity, transform;
  transition-duration: 0.6s;
  position: relative;
  /* background-color: blue; */
  padding-bottom: 100px;
}

.headshot {
  height: 396px;
  width: 284px;
}

.carousel-item-inactive {
  opacity: 0.4;
}

.details-right {
  display: flex;
  flex: 2;
  gap: 8px;
  align-items: flex-start;
  flex-direction: column;
}

.details-left {
  display: flex;
  flex: 3;
  flex-direction: column;
  gap: 24px;
  margin-right: 5%;
}

.name {
  margin-bottom: 16px;
}

.pos-0 {
  margin-left: -50px;
}

.pos-1 {
  transform: scale(0.8);
  opacity: 0.36;
}

.pos-2 {
  transform: scale(0.75);
  opacity: 0.32;
}

.pos-3 {
  transform: scale(0.7);
  opacity: 0.28;
}

.pos-4 {
  transform: scale(0.65);
  opacity: 0.24;
}

.section-container {
  display: flex;
}

.section-label {
  display: flex;
  flex: 1;

  color: var(--text-light-gray, #928183);
}

.section-icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.section-data {
  flex: 2;
}

.favorite-label {
  color: var(--text-light-gray, #928183);
  white-space: nowrap;
}

.favorite-data {
  color: var(--text-white, #fff);
}

.favorite-left {
  border-radius: 24px;
  padding: 4px 8px;
  gap: 4px;
  border: 1px solid var(--backgrounds-primary-neutral, #3f3637);
  background: var(--backgrounds-primary, #120e0f);
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.favorite-container {
  display: flex;
  border-radius: 24px;
  padding-right: 8px;
  border: 1px solid var(--backgrounds-primary-neutral, #3f3637);
  background: var(--backgrounds-primary-neutral, #3f3637);
  align-items: center;
  gap: 8px;
  flex-shrink: 1;
  flex: 0;
  flex-grow: 0;
}

.button-host {
  max-width: 400px;
}

.favorite-header {
  color: var(--text-light-gray, #928183);
  margin-bottom: 16px;
}

@media only screen and (max-width: 799px) {
  .header-hosts {
    margin-left: 6vw;
  }

  .details-container {
    flex-direction: column;
    padding: 0px 6vw 7% 6vw;
  }

  .help-wanted-header {
    margin-bottom: 48px;
  }

  .help-wanted-container {
    padding: 48px 6vw;
    flex-direction: column;
    min-height: 500px;
  }

  .name {
    margin-bottom: 0px;
  }

  .favorite-header {
    margin-top: 16px;
    /* Web/H4 600 */
    font-family: DM Sans;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.1125rem;
  }

  .details-left {
    margin-right: 0px;
  }

  .help-wanted-right {
    margin-top: 20px;
    position: absolute;
    right: 6vw;
  }

  .help-wanted-left {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-right: 0px;
    justify-content: space-between;
  }

  .eyes {
    width: 131px;
    height: 92px;
  }

  .section-data {
    text-align: right;
  }
}

@media only screen and (max-height: 820px) {
  .arrows {
    position: absolute;
    top: 80vh;
  }

  .microphone {
    top: 45%;
  }
}
