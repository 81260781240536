.content-left {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 1%;
}

.show-container {
  border-radius: 8px;
  background: var(--backgrounds-tertiary, #1c1617);
  padding: 16px;
}

.show-description {
  color: var(--text-light-gray, #928183);
}

.watch-left {
  display: flex;
  flex-shrink: 1;
  align-items: center;
  gap: 12px;
  justify-content: space-between;
}

.live-dot {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: var(--neon-bright-red, #ff8f8f);
  fill: var(--neon-bright-red, #ff8f8f);
  box-shadow: 0px 0px 1px 0px #fd0015 inset;
  filter: drop-shadow(0px 0px 4px #fd0015);
}

.watch-title {
  width: auto;
  text-overflow: ellipsis;
  flex-shrink: 1;
}

.watch-right {
  display: flex;
  align-items: center;
  min-width: 140px;
  flex-shrink: 1;

  color: var(--text-light-gray, #928183);
  text-align: right;
}

.watch-time {
  margin-left: 20px;
  color: var(--text-white, #fff);
  text-align: right;
}

.watch-header {
  justify-content: space-between;
  display: flex;
  margin: 16px 0px;
  align-items: center;
  color: var(--text-white, #fff);
}

.content-watch {
  display: flex;
  flex: 1;
  gap: 16px;
}

.live {
  color: var(--neon-bright-red, #ff8f8f);

  /* Neon/Bright/Red */
  text-shadow: 0px 0px 4px #fd0015;
  height: 24px;
  display: flex;
  padding: 0px 8px;
  align-items: center;
  gap: 6px;
  display: flex;
  border-radius: 24px;
  border: 1px solid var(--neon-bright-red, #ff8f8f);
  background: var(--neon-dark-red, #271617);

  /* Neon/Bright/Red */
  box-shadow:
    0px 0px 1px 0px #fd0015 inset,
    0px 0px 4px 0px #fd0015;
}

.host-image {
  position: absolute;
  width: 30px;
  height: 30px;
  left: -3px;
  top: -3px;
}

.host {
  height: 24px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  padding: 4px 8px 4px 28px;
  align-items: center;
  gap: 4px;
  display: flex;
  border-radius: 24px;
}

.hosts-container-small {
  display: flex;
  gap: 12px;
}

.watch-header-left-container {
  display: flex;
  gap: 8px;
  align-items: center;
}

.video {
  height: calc(100vh - 80px - 48px);
  width: 100%;
  border-radius: 4px;
  background: var(--backgrounds-primary, #120e0f);
  justify-content: center;
  align-items: center;
  display: flex;
  box-sizing: border-box;

  color: var(--text-white, #fff);

  /* Web/Medium 500 */
  font-family: DM Sans;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.05rem;
}

.chat {
  flex: 1;
  background-color: var(--backgrounds-primary, #120e0f);
  padding: 12px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  justify-content: flex-end;
}

.show-info {
  margin-top: 15px;

  color: var(--text-white, #fff);

  /* Web/Medium 500 */
  font-family: DM Sans;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.05rem;
}

.chat-container {
  display: flex;
  flex-direction: column;
  width: 340px;
  height: calc(100vh - 80px - 48px);
  position: sticky;
}

.chat::-webkit-scrollbar {
  display: none;
}

.calendar {
  background-color: var(--backgrounds-primary, #120e0f);
  display: flex;
  overflow: scroll;
  height: 120px;
  /* -ms-overflow-style: none; 
  scrollbar-width: none; */

  flex-wrap: no-wrap;
  overflow-x: auto;

  direction: ltr;
  scrollbar-width: thin; /*fancy width*/
  scrollbar-color: #f3f0dd #154734; /*fancy colors for Firefox*/
}

.calendar::-webkit-scrollbar {
  display: none;
}

.watch-container {
  background: url(assets/watchBG.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  background-color: lightgray;
  background-position: 50%;
  gap: 48px;
  padding: 24px 0px 48px 5%;
}

.calendar-item {
  flex-direction: column;
  position: relative;
  border: 1px solid var(--backgrounds-primary-neutral, #3f3637);
}

.calendar-item-header {
  height: 16px;
  display: flex;
  justify-content: center;

  color: var(--text-light-gray, #928183);

  /* Web/XX-Small 500 */
  font-family: DM Sans;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 500;
  line-height: 0.75rem; /* 120% */
}

.calendar-header {
  margin-left: 8px;
}

.calendar-big {
  flex: 1;

  color: var(--text-light-gray, #928183);
  font-family: DM Sans;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.75rem; /* 100% */
}

.calendar-content {
  margin: 8px;
}

.calendar-left {
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid var(--backgrounds-primary-neutral, #3f3637);
  border-bottom: 1px solid var(--backgrounds-primary-neutral, #3f3637);
  background: var(--backgrounds-secondary, #261e1f);

  color: var(--text-white, #fff);
  text-align: center;
}

.calendar-column {
  display: flex;
  width: 100px;
  min-width: 100px;
  flex: 1;
  flex-direction: column;
}

.empty-show {
  background: url(assets/boxGray.svg);
  background-repeat: no-repeat;
  background-size: cover;
}

.show {
  background: var(--backgrounds-gradients-navigation, linear-gradient(175deg, #261e1f 3.18%, #120e0f 95.5%));
  height: 100px;
  position: absolute;
  padding: 8px;
  box-sizing: border-box;
  gap: 4px;
  display: flex;
  flex-direction: column;
  z-index: 10;
}

.chat-line {
  color: #d9d9d9;
  font-family: Lexend;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1rem; /* 133.333% */
}

.chat-send {
  display: flex;
  flex-shrink: 1;
  align-items: center;
  gap: 8px;
  padding: 0px 8px;
  margin-top: 12px;
}

.arrow-right-watch {
  width: 9.75px;
  height: 18px;
  transform: scaleX(-1);
  filter: brightness(0) saturate(100%) invert(60%) sepia(11%) saturate(331%) hue-rotate(304deg) brightness(85%)
    contrast(88%);
}

.send {
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: space-between;
  align-items: center;
}

.chat-name {
  color: var(--neon-bright-red, #ff8f8f);

  /* Neon/Bright/Red */
  text-shadow: 0px 0px 4px #fd0015;

  /* Small/400 */
  font-family: Lexend;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem; /* 133.333% */
}

.input-watch {
  display: flex;
  flex: 1;
  box-sizing: border-box;
  height: 40px;
  min-height: 40px;
  max-height: 80px;
  color: var(--text-light-gray, #928183);
  resize: none;
  outline: none;
  background-color: transparent;
  border-width: 0px;
  padding: 10px 16px;
  border-radius: 24px;
  border: 1px solid var(--backgrounds-primary-neutral, #3f3637);

  /* Medium/400 */
  font-family: Lexend;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem; /* 128.571% */
}

.show-name {
  color: var(--neon-bright-orange, #ffc58f);
}

.show-description {
  color: var(--text-light-gray, #928183);

  /* Web/X-Small 400 */
  font-family: DM Sans;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.75rem; /* 100% */
}

.tab-container {
  display: none;
  border-radius: 4px;
  background: var(--backgrounds-primary, #120e0f);
  margin: 0px 6vw;
}

.video-stream {
  border: 0;
}

.tab-item {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  border-radius: 4px;
  transition: all ease 0.6s;
  border: 1px solid var(--backgrounds-primary, #120e0f);
}

.tab-title {
  color: var(--text-light-gray, #928183);
  font-family: Lexend;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1rem; /* 133.333% */
}

.tab-title-active {
  color: var(--neon-bright-blue, #8ff2ff);
  /* Neon/Bright/Blue */
  text-shadow: 0px 0px 4px #00a2fd;
}

.tab-item-active {
  border: 1px solid var(--neon-bright-blue, #8ff2ff);
  /* Neon/Bright/Blue */
  box-shadow:
    0px 0px 1px 0px #007cc2 inset,
    0px 0px 4px 0px #00a2fd;
}

@media only screen and (max-width: 1000px) {
  .watch-right {
    display: none;
  }

  .watch-left {
    flex: 1;
  }
}

@media only screen and (max-width: 799px) {
  .hosts-container-small {
    flex-direction: row;
  }

  .tab-container {
    display: flex;
    margin: 0px 12px;
  }

  .chat-send {
    padding: 0px 12px;
  }

  .content-watch {
    flex-direction: column;
  }

  .watch-container {
    padding: 0px;
  }

  .watch-header {
    padding: 0px 12px;
  }

  .show-container {
    margin: 16px 12px 0px;
  }

  .calendar {
    margin: 0px 12px;
  }

  .content-left {
    flex: 1;
    width: auto;
  }

  .chat-container {
    width: auto;
    margin-bottom: 24px;
    position: relative;
  }
}
