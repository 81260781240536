.error-page {
  height: calc(100vh - 80px);
  background: var(--backgrounds-primary, #120e0f);
  display: flex;
  flex-direction: column;
}

.error-header {
  color: var(--neon-bright-red, #ff8f8f);
  /* Element Glow/Red */
  text-shadow: 0px 0px 20px #fd0015;

  margin-top: 10vh;
  margin-left: 10vw;
}

.error-gif {
  width: 359px;
  height: 213px;
  border-radius: 8px;
  align-self: flex-end;
  margin-right: 10vw;
}

.error-text {
  color: var(--text-light-gray, #928183);
  margin-left: 10vw;
  margin-top: 12px;
  width: 40%;
}
