.header-partners {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 80px);
  min-height: 800px;
}

.header-partners-text {
  width: 70%;
  margin-left: 10%;
}

.partner-description-container {
  border-radius: 8px 0px 0px 8px;
  background: var(--backgrounds-primary, #120e0f);
  padding: 32px;
  gap: 10px;
  width: 50%;
  align-self: flex-end;
  position: relative;
  align-items: center;
}

.chart-gif {
  width: 284px;
  height: 200px;
  position: absolute;
  top: 40px;
  left: calc(-10vw + -284px);
}

.partner-description-content {
  width: 75%;
  color: var(--text-white, #fff);
}

.haha {
  color: var(--neon-bright-orange, #ffc58f);
}

.partner-container {
  height: auto;
  background: var(--backgrounds-primary, #120e0f);
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.tasty-container {
  margin-top: -40px;
  border-radius: 8px;
  border: 2px solid var(--neon-bright-orange, #ffc58f);
  background: var(--backgrounds-gradients-navigation, linear-gradient(180deg, #261e1f 0%, #120e0f 100%));
  height: auto;
  min-height: 50vh;
  max-width: 90%;
  width: 90vw;
  padding: 5vh 0vw;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-bottom: 20vh;
}

.legal {
  color: var(--text-light-gray, #928183);
  /* Web/XX-Small 400 */
  font-family: DM Sans;
  font-size: clamp(0.5rem, 2vw, 10px);
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 120% */
}

.tasty-logo {
  max-width: 100%;
}

.tasty-left {
  max-width: 30vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin-right: 10vw;
}

.tagline {
  color: var(--text-white, #fff);
  text-align: center;

  margin-top: 12px;
  margin-bottom: 18px;
}

.tasty-right {
  max-width: 30vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  gap: 8px;
}

.type {
  display: flex;
  height: 32px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  color: var(--text-white, #fff);
}

.type-container {
  cursor: pointer;
  display: flex;
  padding: 16px;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid var(--backgrounds-primary-neutral, #3f3637);
  background: var(--backgrounds-tertiary, #1c1617);

  color: var(--text-white, #fff);
}

.trading-icon {
  width: 32px;
  height: 32px;
  padding: 3px;
}

.chevron-right {
  width: 24px;
  height: 24px;
}

.type-name {
  gap: 8px;
  display: flex;
  align-items: center;
}

.legal-margin {
  margin-top: 24px;
}

.type-text {
  display: flex;
  max-height: 0px;
  overflow: hidden;
  transition: all ease 0.6s;
}

.text-expanded {
  height: auto;
  margin-top: 16px;
  max-height: 200px;
}

.long-button {
  width: 100%;
}

@media only screen and (max-width: 799px) {
  .partner-description-container {
    width: auto;
    margin-left: 6vw;
  }

  .header-partners-text {
    width: auto;
    margin: 64px 6vw 24px;
  }

  .header-partners {
    justify-content: flex-start;
  }

  .background-partners {
    height: 120vh;
  }

  .background-tint-partners {
    height: 120vh;
  }

  .header-partners {
    height: calc(120vh - 80px);
  }

  .tasty-container {
    flex-direction: column;
  }

  .tasty-left {
    max-width: 100%;
    padding: 12px 24px;
    margin: 0px;
  }

  .tasty-right {
    max-width: 100%;
    padding: 12px 24px;
  }

  .chart-gif {
    max-width: 100%;
    position: absolute;
    top: 270px;
    left: -6vw;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
  }

  .partner-description-content {
    width: 100%;
  }
}
