.rounded-button {
  cursor: pointer;
  width: 134px;
  height: 44px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--backgrounds-primary, #120e0f);
  transition: all 0.5s;
}

.rounded-button-label {
  color: var(--backgrounds-primary, #120e0f);
  text-align: center;
}

.rounded-button:hover {
  background: #120e0f;

  color: var(--text-light-gray, #928183);
}

.button-red:hover {
  color: #ff8f8f;
  /* Neon/Bright/Red */
  text-shadow: 0px 0px 4px #FD0015;
  border: 1px solid #ff8f8f;
}

.button-blue:hover {
  color: #8ff2ff;
  /* Neon/Bright/Blue */
  text-shadow: 0px 0px 4px #00A2FD;
  border: 1px solid #8ff2ff;
}

.button-yellow:hover {
  color: #fdff8f;
  /* Neon/Bright/Yellow */
  text-shadow: 0px 0px 4px #FDD400;
  border: 1px solid #fdff8f;
}

.button-orange:hover {
  color: #ffc58f;
  /* Neon/Bright/Orange */
  text-shadow: 0px 0px 4px #EF5600;
  border: 1px solid #ffc58f;
}

.button-green:hover {
  color: #8fffa7;
  text-shadow: 0px 0px 4px #00FD65;
  border: 1px solid #8fffa7;
}
