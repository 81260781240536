.footer {
  border-top: 1px solid var(--backgrounds-primary-neutral, #3f3637);
  background: var(--backgrounds-primary, #120e0f);
  display: flex;
  padding: 53px 5vw;
}

.footer-link {
  color: var(--text-white, #fff);
  /* Web/Medium 500 */
  font-family: DM Sans;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.05rem;
}

.logo-gif-footer {
  max-width: 259px;
  width: 100%;
  height: auto;
}

.footer-category-container {
  gap: 12px;
  display: flex;
  flex-direction: column;
  margin-right: 5vw;
  color: var(--text-light-gray, #928183);
}

.big-footer-container {
  min-width: 210px;
  margin-right: 0px;
}

.footer-link-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

@media only screen and (max-width: 768px) {
  .footer {
    flex-direction: column;
    padding: 0px 6vw 32px;
  }

  .logo-gif-footer {
    width: 124px;
    height: 107px;
  }

  .footer-link-container {
    flex-direction: row;
  }

  .footer-category-container {
    margin-right: 0px;
    margin-top: 32px;
  }
}
