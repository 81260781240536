.header {
  display: flex;
  height: calc(100vh - 80px);
  min-height: 800px;
  max-width: 1000px;
  flex-direction: column;
  padding: 0% 8%;
}

.header-top {
  display: flex;
  margin-top: 10%;
  margin-bottom: 10%;
}

.header-text {
  color: var(--backgrounds-primary, #120e0f);
}

.header-text-small {
  color: var(--backgrounds-primary, #120e0f);
}

.arrowRight {
 width: 40px;
 height: 40px;
}

.ufo {
  position:absolute;
  width: 120px;
  height: 120px;
  margin-left: -3%;
  margin-top: -3%;
  z-index: 10;
}

.itemRow {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1%;
}

.content {
  display: flex;
  flex-direction: column;
  background: var(--backgrounds-primary, #120e0f);
  align-items: center;
}

.about {
  flex: 1;
  color: var(--text-light-gray, #928183);
  margin-right: 5vw;
  margin-top: 10vh;
}

.content-container {
  flex-direction: row;
  display: flex;
  margin-bottom: 15vh;
  max-width: 1000px;
}

.highlight-purple {
  color: var(--neon-bright-purple, #c08fff);
  text-shadow: 0px 0px 20px #d900fd;
  z-index: 10;
}

.highlight-red {
  color: var(--neon-bright-red, #ff8f8f);
  text-shadow: 0px 0px 20px #fd0015;
  align-self: flex-end;
  margin-right: -2vw;
  z-index: 10;
}

.highlight-yellow {
  color: var(--neon-bright-yellow, #FDFF8F);
  text-shadow: 0px 0px 20px #FFDE21;
  align-self: flex-end;
  margin-left: 10%;
  z-index: 10;
}

.highlight-green {
  color: var(--neon-bright-green, #8fffa7);
  display: flex;
  margin-left: 10%;
  text-shadow: 0px 0px 20px #00fd0a;
  z-index: 10;
}

.purple-alignment {
  text-align: right;
  display: flex;
  margin-left: -2vw;
}

.info-text {
  color: var(--text-white, #fff);
  margin-bottom: 2%;
}

.promo-background {
  background: url(assets/contentBackground.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  background-color: lightgray;
  background-position: 50%;
  margin-bottom: -2vh;
  min-height: 60vh;
  width: 100%;
  justify-content: center;
  display: flex;
}

.mailing-inner-container {
  padding: 15vh 8%;
}

.mailing-container {
  background: #ff8f8f;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.mailing-header {
  color: var(--backgrounds-primary, #120e0f);
}

.mailing-text {
  color: var(--backgrounds-primary-neutral, #3f3637);
  font-family: DM Sans;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.75rem;
  margin-top: 32px;
}

.landing-button {
  margin-top: 16px;
}

.chase-gus {
  margin-top: 5vh;
  max-width: 100%;
  border-radius: 8px;
}

.info-container-right {
  border-radius: 8px;
  /* background: var(--backgrounds-primary, #120e0f); */
  padding-top: 7%;
  padding-left: 10%;
  padding-right: 0px;
  justify-content: center;
}

.info-container-left {
  border-radius: 8px;
  /* background: var(--backgrounds-primary, #120e0f); */
  padding-top: 7%;
  padding-right: 10%;
  padding-left: 0px;
  justify-content: center;
}

.margin-content {
  padding: 10vh 7vw 0px 7vw;
}

.photo-container {
  flex: 2;
  display: flex;
  flex-direction: column;
}

.photo {
  max-width: 100%;
}

.photo-higher {
  margin-top: 5vh;
}

.small-content-container {
  flex-direction: column;
  z-index: 2;
  display: flex;
  flex: 3;
}

.rounded-button-light {
  cursor: pointer;
  width: 134px;
  height: 44px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--backgrounds-primary-neutral, #3f3637);
  background: var(--backgrounds-primary, #120e0f);
}

.rounded-button-label-light {
  color: var(--text-light-gray, #928183);
  text-align: center;
}

.promo-container {
  border-radius: 8px;
  border: 2px solid var(--neon-bright-red, #ff8f8f);
  background: #120e0f;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 6vh;
  padding: 5% 10%;
  box-sizing: border-box;
}

.gif-logo-home {
  max-width: 50%;
  align-self: center;
}

.promo {
  max-width: 100%;
}

.column-promo {
  flex-direction: column;
  display: flex;
  flex: 1;
  align-items: flex-end;
}

.promo-header {
  color: var(--text-white, #fff);
  font-family: Lexend;
  margin-bottom: 12px;
}

.promo-subline {
  color: var(--neon-bright-red, #ff8f8f);
  margin-bottom: 32px;
}

.promo-content-container {
  display: flex;
  flex-direction: column;
  flex: 1.5;
  margin-left: 10%;
  justify-content: center;
}

.button-left {
  margin-right: 16px;
}

.mail {
  max-width: 100%;
}

.mail-photo-container {
  flex: 1;
}

.mailing-bottom-container {
  display: flex;
}

.mailing-form {
  flex: 1.5;
  display: flex;
  flex-direction: column;
  min-height: 475px;
}

.rounded-button-mail {
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid var(--backgrounds-primary, #120e0f);
  padding: 14px 0px 16px 0px;
  justify-content: center;
  align-items: center;
}

.rounded-button-label-mail {
  color: var(--backgrounds-primary, #120e0f);
  text-align: center;
}

.text-input {
  border-radius: 0.25rem;
  border: 1px solid var(--backgrounds-primary, #120e0f);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 7px 12px 8px 12px;
  background-color: transparent;
  margin-bottom: 12px;
  margin-top: 32px;
  width: 60%;
}

.input {
  color: var(--backgrounds-primary, #120e0f);
  /* Web/Medium 400 */
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.8px;
  outline: none;
  border-width: 0px;
  background-color: transparent;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #23232329;
  }
}

.input-label {
  color: var(--backgrounds-primary, #120e0f);
}

.disabled {
  pointer-events: none;
  cursor: default;
}

.mailing-form-iframe {
  flex: 1;
  width: 320px;
  max-width: 100%;
}

.form_15iP5 {
  margin: 0px;
}

@media only screen and (max-width: 799px) {
  .gif-logo-home {
    max-width: 30%;
  }

  .content-container {
    padding-left: 6vw;
    padding-right: 6vw;
  }

  .promo {
    max-width: 40%;
    align-self: center;
  }

  .promo-container {
    width: 85%;
    z-index: 10;
    flex-direction: column;
    padding: 5%;
  }

  .about {
    margin-top: 24px;
    margin-right: 0px;
  }

  .promo-content-container {
    margin-left: 0px;
  }

  .promo-header {
    font-weight: 500;
    text-align: center;
    margin-top: 12px;
  }

  .button-container-promo {
    text-align: center;
  }

  .promo-subline {
    text-align: center;
  }

  .mailing-bottom-container {
    flex-direction: column;
    margin-bottom: 20px;
  }

  .mailing-container {
    position: relative;
  }

  .mail {
    max-width: 40%;
    position: absolute;
    bottom: 30px;
    right: 6vw;
  }

  .info-container-left {
    background: var(--backgrounds-primary, #120e0f);
  }
}

@media only screen and (max-width: 500px) {

  .small-content-container { 
    align-items: center;
  }
  
  .itemRow {
    visibility: hidden;
    height: 0px;
  }

  .info-text {
    text-align: center;
    margin: 0px;
  }

  .info-container-right {
    padding-left: 0px;
  }

  .info-container-left {
    padding-right: 0px;
  }

  .highlight-purple {
    text-align: center;
  }
  
  .highlight-red {
    text-align: center;
    margin: 0px;
    align-self: normal;
  }
  
  .highlight-yellow {
    text-align: center;
    margin: 0px;
    align-self: normal;
  }
  
  .highlight-green {
    margin: 0px;
    text-align: center;
  }

  .content-container {
    flex-direction: column;
  }

  .content-container-reverse {
    flex-direction: column-reverse;
  }

  .photo-container {
    position: relative;
    flex: 2;
    display: flex;
    flex-direction: column;
  }

  .margin-content {
    margin-bottom: 5vh;
    padding-top: 10vh;
  }

  .h2 {
    letter-spacing: -0.2rem;
  }

  .rounded-button-light {
    width: 40%;
  }
}
