.header-contact {
  color: var(--backgrounds-primary, #120e0f);
  display: flex;
  justify-content: center;
  height: calc(100vh - 80px);
  min-height: 800px;
  padding: 0px 10vw;
}

.header-left-contact {
  display: flex;
  align-items: center;
  position: relative;
  flex: 3;
}

.header-right-contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
  flex: 2;
  min-width: 400px;
  flex-direction: column;
}

.arrow {
  width: 358px;
  height: 186.825px;
  position: absolute;
  bottom: 0px;
  top: 0px;
}

.name-container {
  display: flex;
  gap: 12px;
}

.text-input-form {
  background: var(--neon-bright-green, #8fffa7);
  border-radius: 0.25rem;
  border: 1px solid var(--backgrounds-primary, #120e0f);
  display: flex;
  flex: 1;
  height: 48px;
  max-height: 48px;
  flex-direction: column;
  justify-content: center;
  padding: 0px 12px;
  background-color: #8fffa7;
  color: var(--backgrounds-primary, #120e0f);
}

.big {
  max-height: 180px;
  min-height: 100px;
  justify-content: flex-start;
  padding-top: 7px;
}

.big-input {
  flex: 1;
  display: flex;
  resize: none;
}

.brokerage-warning {
  border-radius: 4px;
  background: var(--backgrounds-primary, #120e0f);
  display: flex;
  padding: 16px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
}

.brokerage-warning-header {
  color: #8fffa7;
  text-align: center;
}

.topic-gif {
  width: 48px;
  height: auto;
  position: absolute;
  left: -54px;
}

.brokerage-warning-text {
  color: #8fffa7;
  text-align: center;
  display: flex;
  align-items: flex-end;
}

.brokerage-link {
  color: var(--text-white, #fff);
  font-family: DM Sans;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.05rem;
  text-decoration-line: underline;
  margin-left: 3px;
}

.submit-form {
  align-self: flex-end;
  width: 40%;
}

.contact-highlight {
  color: var(--backgrounds-primary, #120e0f);
  position: relative;
  cursor: pointer;
}

.submit-button {
  width: 100%;
}

.dropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.arrow-right-contact {
  width: 24px;
  height: 24px;
  transition: all ease 0.6s;
  transform: scaleX(-1);
  filter: brightness(0) saturate(100%) invert(2%) sepia(4%) saturate(4317%) hue-rotate(295deg) brightness(92%)
    contrast(92%);
}

.arrow-animate {
  transform: scaleX(-1) rotate(-90deg);
  filter: brightness(0) saturate(100%) invert(84%) sepia(10%) saturate(1666%) hue-rotate(76deg) brightness(106%)
    contrast(103%);
}

.dropdown-container {
  transition: 0.3s;

  position: absolute;
  top: 48px;
  right: 0px;
  left: 0px;

  margin: 0;
  padding: 0;

  list-style: none;

  z-index: 99;

  border-radius: 4px;
  border: 0px solid var(--backgrounds-primary, #120e0f);

  height: 0px;
  overflow: hidden;
}

.dropdown-expanded {
  height: 293px;
  border: 1px solid var(--backgrounds-primary, #120e0f);
}

.item {
  cursor: pointer;
  border-bottom: 1px solid var(--backgrounds-primary, #120e0f);
  background: var(--neon-bright-green, #8fffa7);
  display: flex;
  height: 48px;
  padding-left: 12px;
  align-items: center;

  color: var(--backgrounds-primary, #120e0f);

  /* Web/Small 500 */
  font-family: DM Sans;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.125rem; /* 128.571% */
}

.dropdown-container li:last-child {
  border-bottom: none;
}

.text-input-expanded {
  border: 1px solid var(--backgrounds-primary, #120e0f);
  background: var(--backgrounds-primary, #120e0f);
  color: var(--neon-bright-green, #8fffa7);
}

.submit-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  border: 2px solid var(--backgrounds-primary, #120e0f);
  color: #000;
  text-align: center;
  padding: 50px 75px;
  gap: 16px;
}

.input-contact {
  width: 100%;
  color: var(--backgrounds-primary, #120e0f);
  /* Web/Medium 400 */
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.8px;
  outline: none;
  border-width: 0px;
  background-color: transparent;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #23232329;
  }
}

.submit-gif {
  width: 284px;
  height: 183px;
  border-radius: 8px;
}

@media only screen and (max-width: 799px) {
  .header-contact {
    flex-direction: column;
    padding: 0px 6vw;
  }

  .header-left-contact {
    flex: 1;
  }

  .header-right-contact {
    margin-bottom: 48px;
    min-width: auto;
  }

  .submit-container {
    padding: 26px 46px;
  }
}
