.h1 {
  /* Web/H1 Super 600 */
  font-family: DM Sans;
  font-size: clamp(5rem, 12vw, 9.25rem);
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 9.25rem */
  letter-spacing: -0.4625rem;
}

.h5 {
  /* Web/H5 600 */
  font-family: DM Sans;
  font-size: clamp(1rem, 4vw, 1.5rem);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.075rem;
}

.h4 {
  /* Web/H4 600 */
  font-family: DM Sans;
  font-size: clamp(1.5rem, 3vw, 2.25rem);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.1125rem;
}

.p1 {
  font-family: Lexend;
  font-size: clamp(1rem, 2vw, 1.2rem);
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -0.1125rem;
}

.h2 {
  /* Web/H2 600 */
  font-family: DM Sans;
  font-size: clamp(2.8rem, 9vw, 6rem);
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 6rem */
  letter-spacing: -0.3rem;
}

.h3 {
  /* Web/H3 500 */
  font-family: DM Sans;
  font-size: clamp(1.25rem, 5vw, 2.5rem);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.125rem;
}

.large-600 {
  /* Web/Large 600 */
  font-family: DM Sans;
  font-size: clamp(1rem, 2vw, 1.125rem);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.05625rem;
}

.large-500 {
  /* Web/Large 500 */
  font-family: DM Sans;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.05625rem;
}

.large-400 {
  /* Web/Large 400 */
  font-family: DM Sans;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.05625rem;
}

.small-400 {
  /* Web/Small 400 */
  font-family: DM Sans;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem;
}

.small-600 {
  /* Web/Small 600 */
  font-family: DM Sans;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 0.875rem;
}

.xx-small {
  /* Web/XX-Small 600 */
  font-family: DM Sans;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 700;
  line-height: 0.75rem;
  letter-spacing: 0px;
}

@media only screen and (max-width: 799px) {
  .h1 {
    letter-spacing: -0.25rem;
  }

  .h4 {
    letter-spacing: -0.075rem;
  }

  .h3 {
    letter-spacing: -0.075rem;
  }

  .h2 {
    letter-spacing: -0.2rem;
  }

  .h5 {
    letter-spacing: -0.05rem;
  }

  .p1 {
    letter-spacing: -0.075rem;
  }
}
