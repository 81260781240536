.topBar {
  display: flex;
  height: 80px;
  position: fixed;
  width: 100%;
  z-index: 99;
  transition: background ease 0.6s;
}

.hover-logo {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.surround {
  position: relative;
}

.surround:hover img[id='shown-logo'] {
  opacity: 0;
}
.surround:hover img[id='hover-logo'] {
  opacity: 1;
}

.header-placeholder {
  height: 80px;
}

.mobile-background {
  width: 100%;
  height: 100%;
  z-index: 99;
  position: fixed;
  visibility: hidden;
  transition: all ease 0.6s;
  backdrop-filter: blur(12.5px);
  opacity: 0;
  margin-left: -6vw;
}

.mobile-background-expanded {
  visibility: visible;
  opacity: 1;
  background: rgba(217, 217, 217, 0.05);
}

.left-container {
  display: flex;
  margin-left: 8%;
  justify-content: center;
  align-items: center;
}

.mobile-container {
  display: none;
}

.center-container {
  display: flex;
  flex: 4;
  align-items: center;
}

.link-left {
  flex: 4;
}

.link-right {
  flex: 1;
}

.right-container {
  display: flex;
  flex: 2;
  gap: 16px;
  align-items: center;
  justify-content: flex-end;
  margin-right: 8%;
}

.logo {
  height: 56px;
  width: 56px;
  transition: all ease 0.3s;
}

.social {
  height: 24px;
  transition: all 0.5s;
}

.social:hover {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(5305%) hue-rotate(20deg) brightness(127%)
    contrast(95%);
}

.link {
  white-space: nowrap;
  color: var(--backgrounds-primary, #120e0f);
  margin-right: 32px;
  transition: all 0.5s;
}

.link:hover {
  color: #ffffff;
}

.close {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.mobile-tray {
  display: none;
  transition: all ease 0.6s;
  overflow: hidden;
  background: var(--backgrounds-primary, #120e0f);
  z-index: 100;
  justify-content: space-between;
  flex-direction: column;
}

.tray-container {
  display: flex;
  flex-direction: column;
  margin: 6vw 24px;
  gap: 16px;
}

.tray-footer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 6vw 24px;
}

.tray-text {
  color: var(--text-light-gray, #928183);
  white-space: nowrap;
  display: flex;
  gap: 8px;
  align-items: center;
}

.tray-button {
  width: 100% !important;
  min-width: 100px;
}

.menu {
  cursor: pointer;
}

.dark-menu {
  filter: invert(53%) sepia(6%) saturate(616%) hue-rotate(304deg) brightness(97%) contrast(87%);
}

.dark-header-row {
  background: var(--backgrounds-primary, #120e0f);
  border-bottom: 1px solid var(--backgrounds-primary-neutral, #3f3637);
}

.dark-header-text {
  color: var(--text-light-gray, #928183);
}

.dark-header-icon {
  filter: invert(58%) sepia(8%) saturate(472%) hue-rotate(304deg) brightness(88%) contrast(85%);
}

@media only screen and (max-width: 1000px) {
  .right-container {
    gap: 12px;
  }
}

@media only screen and (max-width: 799px) {
  .left-container {
    justify-self: flex-end;
    margin: 0px;
    justify-content: flex-end;
  }

  .center-container {
    display: none;
  }

  .right-container {
    display: none;
  }

  .mobile-container {
    display: flex;
    flex: 1;
    align-items: center;
  }

  .topBar {
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    padding: 0px 6vw;
  }

  .mobile-tray {
    display: flex;
    position: fixed;
    width: 0px;
    height: 100%;
    margin-left: -6vw;
  }

  .mobile-tray-expanded {
    width: 70%;
  }
}
