.modal-background {
  width: 100%;
  height: 100%;
  position: fixed;
  backdrop-filter: blur(12.5px);
  background: rgba(217, 217, 217, 0.05);
  opacity: 0;
  transition: all ease 0.4s;
}

.modal-background-visible {
  opacity: 1;
}

.login-modal {
  border-radius: 8px;
  border: 1px solid var(--neon-bright-red, #ff8f8f);
  background: var(--backgrounds-gradients-navigation, linear-gradient(175deg, #261e1f 3.18%, #120e0f 95.5%));
  display: flex;
  flex-direction: column;
  padding: 24px;

  width: 50%;
  max-width: 460px;
  opacity: 0;
  transition: all ease 0.6s;
}

.login-modal-forgot {
  border: 1px solid #c08fff;
}

.modal-container {
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  z-index: -1;

  opacity: 0;
}

.modal-container-visible {
  opacity: 1;
  z-index: 100;
}

.login-modal-visible {
  opacity: 1;
  z-index: 100;
}

.close {
  cursor: pointer;
  width: 15px;
  height: 15px;
  align-self: flex-end;
}

.logo-gif {
  width: auto;
  height: 112px;
  margin-bottom: 24px;
  align-self: center;
  mix-blend-mode: lighten;
}

.modal-header {
  color: var(--neon-bright-red, #ff8f8f);
  /* Neon/Bright/Red */
  text-shadow: 0px 0px 4px #fd0015;
  align-self: center;
}

.modal-header-forgot {
  color: #c08fff;
  text-shadow: 0px 0px 4px #1e1924;
}

.login-input-form {
  border-radius: 4px;
  border: 1px solid var(--backgrounds-primary-neutral, #3f3637);
  background: var(--backgrounds-primary, #120e0f);
  flex-direction: column;
  justify-content: center;
  display: flex;
  padding: 0px 12px;
  height: 48px;
  max-height: 48px;
}

.error {
  border: 1px solid var(--neon-bright-red, #ff8f8f);
}

.input-login {
  color: var(--text-white, #fff);

  /* Web/Medium 400 */
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.8px;

  max-height: 21px;
  outline: none;
  border-width: 0px;
  background-color: transparent;
  flex: 1;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #120e0f inset !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #ffffff !important;
}

.input-header {
  color: var(--text-light-gray, #928183);
}

.password-input-row {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.eye {
  cursor: pointer;
  width: 24px;
  height: 24px;
  flex-shrink: 1;
}

.reminder-text {
  color: var(--text-light-gray, #928183);
}

.requirement-error {
  color: var(--text-red, #f34957);
  margin-left: 4px;
}

.error-icon {
  width: 8px;
  height: 8px;
  margin-right: 6px;
}

.row {
  flex-direction: row;
  align-items: center;
}

.login-container {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.login-button {
  width: 100% !important;
}

.confirm-button:hover {
  color: #c08fff !important;
  text-shadow: 0px 0px 4px #1e1924 !important;
}

.forgot {
  color: var(--neon-bright-red, #ff8f8f);
  text-align: center;
  /* Neon/Bright/Red */
  text-shadow: 0px 0px 4px #fd0015;
  cursor: pointer;
  margin-top: 12px;
}

.error-text-modal {
  color: var(--neon-bright-red, #ff8f8f);
  text-shadow: 0px 0px 4px #fd0015;
  text-align: center;
}

.confirm-forgot {
  color: #c08fff;
  text-shadow: 0px 0px 4px #1e1924;
}

@media only screen and (max-width: 799px) {
  .login-modal {
    width: 100%;
    margin: 0px 6vw;
  }
}
