.app-subtitle {
  color: var(--text-light-gray, #928183);
  margin-top: 4px;
}

.app-subtitle-inactive {
  color: var(--backgrounds-primary, #120e0f);
}

.app-title-row {
  display: flex;
  color: var(--neon-bright-yellow, #fdff8f);
  transition: color ease 0.6s;
}

.app-title-answered {
  color: var(--neon-bright-green, #8fffa7);
}

.app-question {
  background: var(--neon-bright-yellow, #fdff8f);
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #120e0f;
  margin-right: 16px;
  min-width: 130px;
  max-width: 130px;
  height: 31px;
  padding: 0px 8px;
  margin-top: 9px;
  box-sizing: border-box;
  transition: color ease 0.6s;
}

.app-header-text {
  color: var(--neon-bright-yellow, #fdff8f);
  /* Element Glow/Yellow */
  text-shadow: 0px 0px 20px #fdf300;
  padding: 5vh 15vw;
}

.app-subheader-text {
  color: var(--text-light-gray, #928183);
  padding: 24px 15vw 5vh;
}

.app-page {
  display: flex;
  flex-direction: column;
  background: var(--backgrounds-primary, #120e0f);
}

.application-item {
  display: flex;
  padding: 5vh 15vw;
}

.app-container {
  flex: 1;
}

.app-input {
  color: var(--text-white, #fff);

  /* Web/Medium 400 */
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.8px;

  outline: none;
  border-width: 0px;
  background-color: transparent;
  flex: 1;
  width: 100%;

  border-bottom-width: 1px;
  border-color: #3f3637;
}

.question-answered {
  background: var(--neon-bright-green, #8fffa7);
}

.app-submit-container {
  border-radius: 8px;
  border: 1px solid var(--backgrounds-primary-neutral, #3f3637);
  background: var(--backgrounds-primary, #120e0f);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  width: 70%;
  padding: 40px;
  box-sizing: border-box;
  align-self: center;
  margin-bottom: 10vh;
  margin-top: 5vh;
  color: var(--text-light-gray, #928183);
  text-align: center;
}

.app-submit-error {
  color: var(--neon-bright-red, #ff8f8f);
  border: 1px solid var(--neon-bright-red, #ff8f8f);
}

.disabled {
  pointer-events: none;
}

.app-button {
  width: 100%;
}

.yuss {
  border-radius: 8px;
  width: 195px;
  height: 147px;
}

.app-submit-full {
  color: var(--neon-bright-green, #8fffa7);
  border: 1px solid var(--neon-bright-green, #8fffa7);
}

.app-submit-button {
  width: 60%;
}

@media only screen and (max-width: 799px) {
  .app-container {
    padding-right: 0px;
  }

  .application-item {
    flex-direction: column;
    padding: 5vh 6vw;
  }

  .app-header-text {
    padding: 5vh 6vw;
  }

  .app-subheader-text {
    padding: 24px 6vw 5vh;
  }

  .app-question {
    margin-left: -6vw;
    margin-bottom: 16px;
  }

  .app-submit-button {
    width: 100%;
  }
}
